import { ButtonVariant } from '@/components/ButtonVariant';
import { LoadingIcon } from '@/components/LoadingIcon';
import classnames from 'classnames';
import React from 'react';

enum IconPosition {
  None = '',
  Left = 'Button--icon-left',
  Right = 'Button--icon-right',
}

enum PrivateVariant {
  Full = 'Button--full',
  AutoHeight = 'Button--auto-height',
  Progress = 'Button--progress',
  IsLoading = 'Button--isLoading',
}
export interface ButtonProps {
  ref?: (el: HTMLElement) => void;
  variant?: ButtonVariant;
  iconPosition?: IconPosition;
  onClick?: ($event: React.MouseEvent<HTMLElement>) => void;
  type?: string;
  className?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  autoHeight?: boolean;
  progress?: number;
  isLoading?: boolean;
  tag?: string;
  onRef?: (el: HTMLElement) => void;
  id?: string;
  ariaLabel?: string;
  tabIndex?: number;
  children?: React.ReactNode;
}

export class Button extends React.Component<ButtonProps> {
  static Variant = ButtonVariant;
  static IconPosition = IconPosition;
  static defaultProps: Partial<ButtonProps> = {
    ref: () => void 0,
    variant: ButtonVariant.Standard,
    iconPosition: IconPosition.None,
    onClick: () => void 0,
    type: 'button',
    className: '',
    disabled: false,
    fullWidth: false,
    autoHeight: false,
    tag: 'button',
    ariaLabel: '',
    tabIndex: 0,
  };

  private handleOnRef = (el): void => {
    if (typeof this.props.onRef === 'function') {
      this.props.onRef(el as HTMLElement);
    }
  };

  render(): JSX.Element {
    const {
      progress,
      variant,
      fullWidth,
      disabled,
      className,
      type,
      iconPosition,
      autoHeight,
      isLoading,
      tag,
      onClick,
      id,
      ariaLabel,
      tabIndex,
    } = this.props;

    const hasProgress = typeof progress === 'number';
    const isDisabled =
      disabled || isLoading || (hasProgress && progress !== 100);
    const classNames = classnames('Button', variant, className, iconPosition, {
      [PrivateVariant.Full]: fullWidth,
      [PrivateVariant.Progress]: hasProgress,
      [PrivateVariant.AutoHeight]: autoHeight,
      [PrivateVariant.IsLoading]: isLoading,
    });

    const progressStyle = hasProgress
      ? { transform: `translateX(${progress}%)` }
      : {};

    const attributes = {
      ref: this.handleOnRef,
      type: tag !== 'button' ? undefined : type ? type : 'button',
      className: classNames,
      onClick,
      disabled: isDisabled,
      id,
      'aria-label': ariaLabel,
      tabIndex,
    };
    const buttonContent =
      this.props.variant === ButtonVariant.ContentBlock ? (
        this.props.children
      ) : (
        <>
          <span className="h-flex h-flex--center-all">
            {isLoading && <LoadingIcon isLoading={isLoading} />}
            {this.props.children}
          </span>
          {hasProgress && (
            <span className="Button__progress" style={progressStyle} />
          )}
        </>
      );

    return React.createElement(
      this.props.tag || 'button',
      attributes,
      buttonContent
    );
  }
}

export default Button;
