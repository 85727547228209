import Link from 'next/link';

export default function LinkWrapper({ href, tabIndex, children, ...rest }) {
  return href.startsWith('http') ? (
    <a href={href} target="_blank" tabIndex={tabIndex}>
      {children}
    </a>
  ) : (
    <Link href={href} {...rest} prefetch={false} tabIndex={tabIndex}>
      {children}
    </Link>
  );
}
