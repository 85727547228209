interface ApiConfig {
  [api: string]: {
    proxy: string;
    methods: {
      [method: string]: string;
    };
  };
}

interface Api {
  [api: string]: {
    [method: string]: string;
  };
}

export const apiConfig: ApiConfig = {
  subs: {
    proxy: `/api/subs`,
    methods: {
      register: '/register',
      login: '/login',
      subsidiary: '/subsidiary',
      contact: '/contact',
    },
  },
  stats: {
    proxy: `/api/stats`,
    methods: {
      lottoDraws: '/entities.lotto/draws',
      lottoShortinfo: '/entities.lotto/shortinfo',
      eurojackpotShortinfo: '/entities.eurojackpot/shortinfo',
      spiel77Shortinfo: '/entities.zusatzspiel/shortinfo/expanded',
      lottoStatistics: '/entities.lotto/statistics',
      lottoHistogram: '/entities.lotto/histogramm',
      lottoLuckyNumbers: '/entities.lotto/numbers',
      lottoLast: '/entities.lotto/last',
      lottoLastGame: '/entities.lotto/last/game',
      lottoHistory: '/entities.lotto/history',
      lottoBarometer: '/entities.lotto/barometer',
      gluecksspiraleLast: '/entities.gluecksspiraleziehung/last',
      gluecksspiraleHistory: '/entities.gluecksspiraleziehung/history',
      gluecksspiraleDraw: '/entities.gluecksspiraleziehung/draw',
      eurojackpotLast: '/entities.eurojackpot/last',
      eurojackpotHistory: '/entities.eurojackpot/history',
      eurojackpotDraw: '/entities.eurojackpot/draw',
      kenoDraw: '/entities.keno/draw',
      kenoLast: '/entities.keno/last',
      kenoHistory: '/entities.keno/history',
      plus5Draw: '/entities.p5/draw',
      plus5Last: '/entities.p5/last',
      plus5Shortinfo: '/entities.toto_aw/last/completed',
      totoawDraw: '/entities.toto_aw/date',
      totoawLast: '/entities.toto_aw/last',
      totoawShortinfo: '/entities.toto_aw/shortinfo',
      totoawHistory: '/entities.toto_aw/history',
      totoewDraw: '/entities.toto_ew/date',
      totoewLast: '/entities.toto_ew/last',
      totoewShortinfo: '/entities.toto_ew/shortinfo',
      totoewHistory: '/entities.toto_ew/history',
    },
  },
};

const API: Api = Object.keys(apiConfig).reduce((apis, api) => {
  apis[api] = Object.keys(apiConfig[api].methods).reduce((methods, method) => {
    methods[method] = apiConfig[api].proxy + apiConfig[api].methods[method];
    return methods;
  }, {});
  return apis;
}, {});

export default API;
